
import React, { useState, useEffect } from "react";
import Iconify from "./Iconify";
import backtotopicon from '../assets/backtotopicon.svg'
const ScrollToTop = () => {
    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <div className="top-to-btm">
            {" "}
            {showTopBtn && (
                
                <Iconify
                className="icon-position icon-style"
                    onClick={goToTop}
                    style={{ fontSize: 24 }}
                    color={"white"}
                    icon={"bi:arrow-up-square-fill"} />
                // <FaAngleUp
                //     className="icon-position icon-style"
                //     onClick={goToTop}
                // />
            )}{" "}
        </div>
    );
};
export default ScrollToTop;
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import mainBanner from "../assets/honda/temp/brio/briobanner.png";

import variant1 from "../assets/honda/temp/brio/variant/v1.png";

import color1 from "../assets/honda/temp/brio/color/1.png";
import color2 from "../assets/honda/temp/brio/color/2.png";
import color3 from "../assets/honda/temp/brio/color/3.png";
import color4 from "../assets/honda/temp/brio/color/4.png";
import color5 from "../assets/honda/temp/brio/color/5.png";
import color6 from "../assets/honda/temp/brio/color/6.png";
import color7 from "../assets/honda/temp/brio/color/b7.png";
import color8 from "../assets/honda/temp/brio/color/b8.png";
import color9 from "../assets/honda/temp/brio/color/b9.png";

import gallery1 from "../assets/honda/temp/brio/gallery/banner_web_brio_3_final_13a__1613316309591.jpg";
import gallery2 from "../assets/honda/temp/brio/gallery/banner_web_brio_3_final_14__1613316235914.jpg";
import gallery3 from "../assets/honda/temp/brio/gallery/banner_web_brio_3_final_15__1613316147328.jpg";
import gallery4 from "../assets/honda/temp/brio/gallery/banner_web_brio_3_final_16__1613316105009.jpg";
import gallery5 from "../assets/honda/temp/brio/gallery/banner_web_brio_3_final_17__1613316049899.jpg";
import gallery6 from "../assets/honda/temp/brio/gallery/banner_web_brio_3_final_32__1613318578995.jpg";

import TextFormatter from "../util/TextFormatter";
import Palette from "../util/Palette";
import Iconify from "../reusable/Iconify";
import { useEffect, useState } from "react";
import GalleryDetailModal from "../reusable/GalleryDetailModal";
import ItemDAO from "../../daos/ItemDAO";
import { useParams, Link } from "react-router-dom";
import SpecificationDAO from "../../daos/SpecificationDAO";
import VariantDAO from "../../daos/VariantDAO";
import ColorDAO from "../../daos/ColorDAO";
import GalleryDAO from "../../daos/GalleryDAO";

/* const colors = [
    {
        name: "Crystal Black Pearl",
        image: color1
    },
    {
        name: "Taffeta White",
        image: color2
    },
    {
        name: "Modern Steel Metallic",
        image: color3
    },
    {
        name: "Carnival Yellow",
        image: color4
    },
    {
        name: "Rallye Red",
        image: color5
    },
    {
        name: "Carnival Yellow - Two Tone (URBANITE Edition)",
        image: color6
    },
    {
        name: "Phoenix Orange Pearl - Two Tone (URBANITE Edition)",
        image: color7
    },
    {
        name: "Taffeta White - Two Tone (URBANITE Edition)",
        image: color8
    },
    {
        name: "Crystal Black Pearl (URBANITE Edition)",
        image: color9
    },
]

const galleries = [
    {
        title: "Leather Seats",
        image: gallery1,
    },
    {
        title: "Interactive Buttons",
        image: gallery2,
    },
    {
        title: "Adjustable Steering Wheel",
        image: gallery3,
    },
    {
        title: "6\" LCD Display",
        image: gallery4,
    },
    {
        title: "Spacy Interior",
        image: gallery5,
    },
    {
        title: "World Class Safety",
        image: gallery6,
    }
] */

export default function ItemDetail(props) {
    let [selectedGallery, setSelectedGallery] = useState(null);

    let { id } = useParams();
    const [detail, setDetail] = useState();
    const [specification, setSpecification] = useState([]);
    const [variant, setVariant] = useState([]);
    const [color, setColor] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [titleName, setTitleName] = useState('')

    const fetchData = async () => {
        let d = await ItemDAO.getItemById(id);
        console.log('value of ', d);
        setDetail(d);
        setTitleName(d?.name)
        // let s = await SpecificationDAO.getSpecificationById(id);
        // console.log(s);
        // setSpecification(s);
        let v = await VariantDAO.getVariantByItemId(id);
        console.log(v);
        setVariant(v);
        let c = await ColorDAO.getColorByItemId(id);
        console.log(c);
        setColor(c);
        let g = await GalleryDAO.getGalleryByItemId(id);
        console.log(g);
        setGallery(g);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />

                <title>Honda Tren Alam Sutera - Home {`- ` + titleName}</title>
                <link rel="canonical" href="http://komunestudio.com" />
            </Helmet>
            <Container fluid style={{ padding: 0 }}>
                <section>
                    <Row>
                        <Col
                            style={{
                                padding: 0,
                            }}
                            md={12}
                        >
                            <img
                            alt=""
                                style={{
                                    width: "100%",
                                }}
                                src={detail?.banner_url}
                            />
                        </Col>
                    </Row>
                </section>
                <section>
                    <Row style={{ marginTop: 50 }}>
                        <div
                            style={{ width: "100%" }}
                            className={"d-flex flex-column align-items-center"}
                        >
                            <Row
                                style={{
                                    width: "100%",
                                    maxWidth: 1000,
                                    alignSelf: "center",
                                    justifySelf: "center",
                                }}
                            >
                                <Col style={{ marginTop: 35, marginBottom: 35 }} md={12}>
                                    <h3 style={{ fontWeight: "700" }}>VARIANT</h3>
                                </Col>
                                {variant.map((obj, key) => {
                                    return (
                                        <Col
                                            style={{
                                                paddingTop: 10,
                                                paddingBottom: 10,
                                                marginBottom: 50,
                                            }}
                                            className={
                                                "d-flex flex-column align-items-center justify-content-flex-start"
                                            }
                                            md={4}
                                        >
                                            <div
                                                className={"d-flex flex-column"}
                                                style={{
                                                    height: "calc(2.4em + 0.5rem)",
                                                }}>
                                                <h5 style={{ fontWeight: "700", lineHeight: "1.2em", display: "table-cell", verticalAlign: "bottom", margin: 0 }}>{obj.name}</h5>
                                            </div>

                                            <img
                                            alt=""
                                                style={{
                                                    height: 120,
                                                    width: "100%",
                                                    objectFit: "contain",
                                                    objectPosition: "bottom",
                                                    marginTop: 30,
                                                }}
                                                src={obj.image}
                                            />
                                            <div
                                                style={{
                                                    width: "100%",
                                                    paddingLeft: 20,
                                                    paddingRight: 20,
                                                    marginTop: 20,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        // border : `2px solid ${Palette.PRIMARY_COLOR}`,
                                                        background: Palette.PRIMARY_COLOR,
                                                        color: "white",
                                                        borderRadius: 30,
                                                        width: "100%",
                                                        paddingTop: 5,
                                                        paddingBottom: 5,
                                                    }}
                                                >
                                                    <div style={{ fontSize: "0.7em" }}>Harga</div>
                                                    <div style={{ fontWeight: 700 }}>
                                                        {TextFormatter.formatPrice(obj.price)}*
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 style={{ fontWeight: "400", marginTop: 20 }}>
                                                Spesifikasi
                                            </h5>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    paddingLeft: 20,
                                                    paddingRight: 20,
                                                    marginTop: 20,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        // border : `2px solid ${Palette.PRIMARY_COLOR}`,
                                                        background: "#f3f4f6",
                                                        width: "100%",
                                                        paddingTop: 20,
                                                        paddingBottom: 5,
                                                        paddingLeft: 5,
                                                        paddingRight: 5,
                                                        textAlign: "left",
                                                        fontSize: "0.95em",
                                                    }}
                                                >
                                                    <ul>
                                                        {
                                                            obj.specification?.map((obj, index) => {
                                                                return <li>{obj}</li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>
                    </Row>
                </section>
                <section>
                    <Row>
                        <div
                            style={{ width: "100%" }}
                            className={"d-flex flex-column align-items-center"}
                        >
                            <Row
                                style={{
                                    width: "100%",
                                    maxWidth: 1000,
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Col style={{ marginTop: 35, marginBottom: 50 }} md={12}>
                                    <h3 style={{ fontWeight: "700" }}>COLORS</h3>
                                </Col>
                                {color.map((obj, key) => {
                                    return (
                                        <Col style={{ marginBottom: 20 }} md={3}>
                                            <img
                                            alt=""
                                                title={obj.name}
                                                style={{
                                                    height: 80,
                                                    width: "100%",
                                                    objectFit: "contain",
                                                }}
                                                src={obj.image}
                                            />
                                            <div
                                                style={{
                                                    fontWeight: 100,
                                                    marginTop: 5,
                                                    marginBottom: 10,
                                                }}
                                            >
                                                {obj.color}
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>
                    </Row>
                </section>
                {detail?.brochure_url &&
                    <section>
                        <Row
                            style={{
                                background: Palette.PRIMARY_COLOR,
                                marginTop: 70,
                            }}
                        >
                            <Col
                                className={"d-flex align-items-center justify-content-center"}
                                style={{
                                    paddingTop: 30,
                                    paddingBottom: 30,
                                }}
                                md={12}
                            >
                                {
                                    detail?.brochure_url ? <a href={detail?.brochure_url ? detail?.brochure_url : 'javascript:;'} target="_blank">
                                        <div
                                            style={{
                                                width: 300,
                                                border: "3px solid white",
                                                paddingLeft: 50,
                                                paddingRight: 50,
                                                paddingTop: 10,
                                                paddingBottom: 10,
                                                fontSize: "1.1em",
                                                fontWeight: 700,
                                                color: "white",
                                            }}
                                        >
                                            Unduh Brosur <Iconify icon={"ic:file-download"} />

                                        </div>
                                    </a> : ''

                                }
                            </Col>
                        </Row>
                    </section>
                }

                {/*<section title={"Galeri"}>*/}
                <Row
                    style={{
                        width: "100%",
                        alignSelf: "center",
                        justifySelf: "center",
                        padding: 0,
                        margin: 0,
                    }}
                >
                    <Col style={{ marginTop: 35, marginBottom: 35 }} md={12}>
                        <h3 style={{ fontWeight: "700" }}>GALERI</h3>
                    </Col>
                    {gallery.map((obj, key) => {
                        return (
                            <Col
                                onClick={() => {
                                    setSelectedGallery(obj);
                                }}
                                className={"d-flex flex-column"}
                                style={{
                                    height: 300,
                                    backgroundSize: "cover",
                                    background: `url(${obj.image_url})`,
                                    backgroundPosition: "center",
                                    padding: 0,
                                    cursor: "pointer",
                                }}
                                md={4}
                            >
                                <div
                                    className={"d-flex justify-content-end align-items-end"}
                                    style={{
                                        flex: 1,
                                        background: "#00000040",
                                        color: "white",
                                        padding: 10,
                                        paddingRight: 20,
                                        paddingLeft: 30,
                                    }}
                                >
                                    <div
                                        style={{
                                            fontWeight: 700,
                                            borderTop: "1px solid white",
                                            width: "100%",
                                            textAlign: "right",
                                        }}
                                    >
                                        {obj.name}
                                    </div>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
                {/*</section>*/}
            </Container>
            <GalleryDetailModal
                gallery={selectedGallery}
                close={() => {
                    setSelectedGallery(null);
                }}
            />
        </>
    );
}

import { Link, Outlet } from "react-router-dom";
import { Button, Col, Container, Navbar, Row } from "react-bootstrap";
import Iconify from "./Iconify";

import logo from "../assets/honda/logo.png"
import Palette from "../util/Palette";
import { useEffect, useState } from "react";
import PopUpPromoModal from "./PopUpPromoModal";
import OrganizationDAO from "../../daos/OrganizationDAO";
import ScrollToTop from "./ScrollToTop";

export default function PageContainer({ }) {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [organization, setOrganization] = useState(null)
    const [popUpImage, setPopUpImage] = useState(null)
    const [formatedPhoneNumber, setFormatedPhoneNumber] = useState()
    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    const fetchData = async () => {
        let organization = await OrganizationDAO.getSelf()
        setOrganization(organization)
        setPopUpImage(organization?.popup_image_url)

        if (organization?.phone_number) {
            if (organization?.phone_number.charAt(0) == '0') {
                const format = organization?.phone_number.substring(1);
                setFormatedPhoneNumber('62' + format)
            }
        }
    };

    useEffect(() => {
        fetchData()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])


    const isMobile = windowWidth < 768


    return (
        <>
            <ScrollToTop />
            <div fluid style={{
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                webkitBoxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                mozBoxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                position: "fixed",
                width: "100%",
                background: "#ffffffcc"
            }}>
                <Container style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                    <Row style={{ maxWidth: 1000, width: "100%" }}>
                        <Col className={"d-flex flex-row align-items-center"} md={2}>
                            {/*<Navbar.Brand>*/}
                            {/*    <Iconify icon={"mdi:hamburger-menu"}/>*/}
                            {/*</Navbar.Brand>*/}
                        </Col>
                        <Col className={"d-flex flex-row align-items-center justify-content-center mt-3 mb-3"} md={8}>
                            <Link to={"/"}>
                                <img
                                    alt=""
                                    height={50}
                                    src={logo} />
                            </Link>
                        </Col>
                        {
                            !isMobile &&
                            <Col style={{ fontWeight: "900" }} className={"d-flex flex-row align-items-center"} md={2}>
                                <a href={`https://wa.me/${organization?.phone_number ? formatedPhoneNumber.replace(/[^0-9]/g, '') : ''}?text=Halo%20Mau%20bertanya%20mengenai%20produk%20Honda`} target="_blank">
                                </a>
                            </Col>
                        }
                    </Row>
                </Container>
            </div>
            <div style={{ height: "calc(2rem + 50px)" }}>

            </div>
            <Outlet />

            <Container fluid style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                background: Palette.PRIMARY_COLOR,
                height: 250,
                color: "white",
                fontWeight: 700,
            }}>
                <a href={`https://wa.me/${organization?.phone_number ? formatedPhoneNumber.replace(/[^0-9]/g, '') : ''}?text=Halo%20Mau%20bertanya%20mengenai%20produk%20Honda`} target="_blank">

                    <div style={{
                        border: "3px solid white",
                        paddingLeft: 50,
                        paddingRight: 50,
                        paddingTop: 10,
                        paddingBottom: 10,
                        fontSize: "1.1em"
                    }}>

                        Hubungi Kami !

                    </div>
                </a>
            </Container>

            <div>
                <Container fluid style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", background: Palette.WHITE, paddingBottom: 30, paddingTop: 50 }}>
                    <Row style={{ maxWidth: 1000, width: "100%" }}>
                        <Col
                            className={"d-flex flex-column justify-content-start align-items-start"}
                            md={6}>
                            <Container fluid>
                                <Row>
                                    <Col
                                        style={{ paddingBottom: 20 }}
                                        className={"d-flex flex-column justify-content-start align-items-start"}
                                        md={6}>
                                        <div style={{ fontWeight: 700 }}>Hubungi Kami</div>
                                        <div style={{ textAlign: "left" }}>
                                            {organization?.contact_name}<br />
                                            {organization?.contact_position && `(${organization.contact_position})`}
                                        </div>
                                    </Col>
                                    <Col
                                        style={{ paddingBottom: 20 }}
                                        className={"d-flex flex-column justify-content-start align-items-start"}
                                        md={6}>
                                        <div style={{ fontWeight: 700 }}>Alamat</div>
                                        <div style={{ textAlign: "left" }}>
                                            {organization?.address}
                                            <br />
                                        </div>
                                    </Col>
                                    <Col
                                        style={{ paddingBottom: 20 }}
                                        className={"d-flex flex-column justify-content-start align-items-start"}
                                        md={6}>
                                        <div style={{ fontWeight: 700 }}>Telefon</div>
                                        <div style={{ textAlign: "left" }}>
                                            {organization?.phone_number}
                                            <br />
                                            {organization?.phone_number_alt.map(p => p).join('\n')}
                                            <br />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col
                            className={"d-flex flex-column justify-content-start align-items-start"}
                            md={6}>
                            <div style={{ fontWeight: 700 }}>Tentang Kami</div>
                            <div style={{ textAlign: "left", whiteSpace: 'pre-line' }}>
                                {organization?.about_us}
                            </div>
                        </Col>
                        <Col md={12}>
                            <div style={{ width: "100%", marginTop: 20 }}>
                                <hr />
                                Powered by <a style={{color:'rgb(254, 190, 16)'}} href="https://komunestudio.com" target='_blank'><strong>komune studio</strong></a> 
                                {/* {new Date().getFullYear()} */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


            <a href={`https://wa.me/${organization?.phone_number ? formatedPhoneNumber.replace(/[^0-9]/g, '') : ''}?text=Halo%20Mau%20bertanya%20mengenai%20produk%20Honda`} target="_blank">
                <div
                    style={{
                        cursor: 'pointer',
                        position: 'fixed',
                        zIndex: 9999999,
                        bottom: 25,
                        right: 25,
                        backgroundColor: '#26d367',
                        width: 48,
                        height: 48,
                        borderRadius: 5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: 'rgb(136, 136, 136) 0px 0px 3px 1px',
                    }}>
                    <Iconify
                        style={{ fontSize: 24 }}
                        color={"white"}
                        icon={"ic:baseline-whatsapp"} />
                </div>
            </a>
            <PopUpPromoModal imageSrc={popUpImage} />

        </>
    );
}

import API from "./API";

let orgID = process.env.REACT_APP_ORGANIZATION_ID

export default class ItemDAO {
    static getItems = async () => {
        return await API.request(`/v1/items/${orgID}`, "GET");
    }

    static getItemById = async (id) => {
        return await API.request(`/v1/item/${id}`, "GET");
    }
}
export default class TextFormatter{
    static validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static validateURL = (url) => {

        try {
            url = new URL(url)
        } catch (e) {
            return false
        }

        return url.protocol === "http:" || url.protocol === "https:";

    }

    static formatPrice = function (nominal) {

        let initialString = nominal + "";
        let fullString = ""

        let counter = 0;

        for (let i = initialString.length - 1; i >= 0; i--) {
            fullString = initialString.charAt(i) + (counter % 3 === 0 && counter > 0 ? "." : "") + fullString
            counter++;
        }

        return "Rp"+fullString
    }

}

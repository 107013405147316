import { Col, Container, Modal, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import banner from "../assets/honda/temp/banner1.jpg"
import Iconify from "./Iconify";

PopUpPromoModal.propTypes = {
    gallery: PropTypes.object,
    close: PropTypes.func
};

export default function PopUpPromoModal({ imageSrc }) {

    let [isModalOpen, setIsModalOpen] = useState(true)
    let [imageHeight, setImageHeight] = useState(null)

    const imageRef = useRef(null)

    useEffect(() => {
        console.log("imageRef", imageRef?.current?.clientHeight)
        if (imageRef?.current?.clientHeight && imageHeight === null) {
            setImageHeight(imageRef?.current?.clientHeight)
        }
        // setHeight(ref.current.clientHeight)
    }, [imageRef])

    useEffect(() => {
        // if (!localStorage.getItem('first_visit')) {
        //     localStorage.setItem('first_visit', "true")
        //     setIsModalOpen(true)
        // }
    }, [])

    return <Modal
        size={"lg"}
        show={isModalOpen}
        onHide={() => {
            setIsModalOpen(false)
        }}
        backdrop="static"
        keyboard={false}
        centered
        style={{
            background: "#00000000"
        }}
    >
        <Container
            style={{
                background: "#00000000"
            }}
            fluid>
            <Row style={{ padding: 0, background: "#eaeaea" }}>
                <Col
                    className={"d-flex flex-row align-items-end justify-content-end"}
                    md={12}>
                </Col>
            </Row>
            <Row style={{ padding: 0 }}>
                <Col
                    className={"d-flex flex-column popup-banner-container"}
                    style={{ background: `url(${banner})`, height: imageHeight}}
                    md={12}>
                    <img
                        ref={imageRef}
                        src={imageSrc}
                    />
                    <div
                        className="popup-banner-close"
                        onClick={() => {
                            setIsModalOpen(false)
                        }}>
                        <Iconify icon={"ri:close-circle-line"} />
                    </div>
                </Col>
            </Row>
        </Container>
    </Modal>
}

import { Col, Container, Row } from "react-bootstrap";
import mainBanner from "../assets/honda/temp/banner1.jpg";
import { Helmet } from "react-helmet";
import model1 from "../assets/honda/temp/civictyper.png";
import model2 from "../assets/honda/temp/brio.png";
import TextFormatter from "../util/TextFormatter";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import BannerDAO from "../../daos/BannerDAO";
import React, { useEffect, useState } from "react";
import ItemDAO from "../../daos/ItemDAO";
import OrganizationDAO from "../../daos/OrganizationDAO";


const demoCarData = [
  {
    id: 1,
    name: "Honda Civic Type R",
    main_image: model1,
    price: 1177000000,
  },
  {
    id: 1,
    name: "New Honda Brio",
    main_image: model2,
    price: 157900000,
  },
];

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, right: "10px", zIndex: "10" }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, left: "10px", zIndex: "10" }}
      onClick={onClick}
    />
  );
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  customPaging: function (slider, i) {
    //return <div className='square' style={{margin: "10px", top: "10px"}}/>
    return <button style={{ width: "50px" }} />;
  },
};

export default function Home(props) {

  let navigate = useNavigate();

  const [banners, setBanners] = useState();
  const [items, setItems] = useState();
  const [organizationData, setOrganizationData] = useState({});

  const fetchData = async () => {
    let b = await BannerDAO.getBanners();
    setBanners(b);
    let i = await ItemDAO.getItems();
    console.log(i);
    setItems(i);
    let organizations = await OrganizationDAO.getSelf()
    setOrganizationData(organizations)

  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Honda Tren Alam Sutera - Home</title>
        <link rel="canonical" href="http://komunestudio.com" />
      </Helmet>
      <Container fluid>
        <section>
          <Row>
            <Col
              style={{
                padding: 0,
              }}
              md={12}
            >
              {/* <img
                        style={{
                            width : "100%"
                        }}
                        src={mainBanner}/> */}
              <Slider {...settings}>
                {banners?.map((item, index) => {
                  return <img style={{ width: "100%" }} src={item.image_url} />;
                })}
              </Slider>
            </Col>
          </Row>
        </section>
        <section>
          <div
            style={{ width: "100%" }}
            className={"d-flex flex-column align-items-center"}
          >
            <Row
              style={{
                width: "100%",
                maxWidth: 1000,
                alignSelf: "center",
                justifySelf: "center",
              }}
            >
              <Col style={{ marginTop: 35, marginBottom: 35 }} md={12}>
                <h3 style={{ fontWeight: "700" }}>MODELS</h3>
              </Col>
              {items?.map(
                (obj, key) => {
                  return (
                    <Col
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        marginBottom: 50,
                      }}
                      onClick={() => {
                        navigate(`/detail/${obj.id}`);
                      }}
                      className={
                        "d-flex flex-column align-items-center justify-content-center"
                      }
                      md={4}
                    >
                      <Link style={{ width: "100%" }} to={"/"}>
                        <div
                          style={{ width: "100%" }}
                          className={
                            "d-flex flex-column align-items-center justify-content-center"
                          }
                        >
                          <img
                            style={{
                              height: 120,
                              width: "100%",
                              objectFit: "contain",
                              objectPosition: "bottom",
                            }}
                            alt=""
                            src={obj.main_image}
                          />
                          <div
                            style={{
                              fontWeight: 700,
                              marginTop: 10,
                              marginBottom: 5,
                            }}
                          >
                            {obj.name}
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingLeft: 20,
                              paddingRight: 20,
                            }}
                          >
                            <div
                              style={{
                                border: "2px solid black",
                                borderRadius: 30,
                                width: "100%",
                                paddingTop: 5,
                                paddingBottom: 5,
                              }}
                            >
                              <div style={{ fontSize: "0.7em" }}>
                                Harga Mulai Dari
                              </div>
                              <div style={{ fontWeight: 700 }}>
                                {TextFormatter.formatPrice(obj.starts_from)}*
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  );
                }
              )}
            </Row>
          </div>
        </section>
      </Container>
    </>

  );
}

import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export default function NotFound(props){

    let navigate = useNavigate()

    useEffect(()=>{
        navigate("/")
    },[])

    return <div>404</div>
}
import {Col, Container, Modal, Row} from "react-bootstrap";
import PropTypes from "prop-types";

GalleryDetailModal.propTypes = {
    gallery: PropTypes.object,
    close: PropTypes.func
};

export default function GalleryDetailModal(props){

    let {gallery, close} = props

    return <Modal
        size={"lg"}
        show={!!gallery}
        onHide={() => {
            close(null)
        }}
        backdrop="static"
        keyboard={false}
        centered
    >
        <Container fluid>
            <Row style={{padding: 0, background: "#eaeaea"}}>
                <Col
                    className={"d-flex flex-row"}
                    style={{
                        padding: 10, fontWeight: 700,
                        paddingRight: 15
                    }}
                    md={12}>
                    <div style={{flex: 1}}>{gallery?.title}</div>
                    <div
                        onClick={() => {
                            close(null)
                        }}
                        style={{
                            cursor: "pointer"
                        }}>
                        x
                    </div>
                </Col>
            </Row>
            <Row style={{padding: 0, background: "#eaeaea"}}>
                <Col
                    style={{
                        padding: 0
                    }}
                    md={8}>
                    <img
                        src={gallery?.image_url}
                        style={{
                            width: "100%",
                            objectFit: "contain"
                        }}
                    />
                </Col>
                <Col
                    style={{
                        background: "#333333",
                        color: "white",
                        padding: 15
                    }}
                    md={4}>
                    <div style={{fontWeight: "bold"}}>
                        {gallery?.name}
                    </div>
                    <div style={{fontSize: "0.8em", marginTop: 10}}>
                        {gallery?.description}
                    </div>
                </Col>
            </Row>
        </Container>
    </Modal>
}

import axios from "axios";

export default class API {
    static request = async (endpoint, method, body) => {
        let token = localStorage.getItem("token");

        let request = {
            method: method,
            headers: {
                "Content-Type": "application/json",
                Authorization: token ? `Bearer ${token}` : null,
                Accept: 'application/json'
            },
            body: JSON.stringify(body)
        };

        let response = await fetch(process.env.REACT_APP_API_BASE_URL + endpoint, request);

        if (response.ok) {
            return await response.json();
        }

        if (response.status === 401) {
            console.error("[ERR] Invalid token. Attempting to reobtain new token.")
            let result = await API.login();
            if (result) {
                console.log("[LOG] Successfully obtained new token. Trying again after 2 seconds.");
                window.location.reload();
            }
        }
        else {
            throw response;
        }
    }
}